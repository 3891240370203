import React from "react"
import Layout from "../components/layout";
import SEO from "../components/seo";
import {Container, CardColumns, Card} from "react-bootstrap";
import {graphql, Link, useStaticQuery} from "gatsby";
import {FaArrowRight} from "react-icons/fa";

const Blog = () => {
	const data = useStaticQuery(graphql`
    query {
      allPrismicBlog {
        nodes {
          id
          uid
          data {
		        content {
		          text
		        }
		        title {
		          text
		        }
            preview_img {
              url
            }
		      }
        }
      }
    }
  `);
	return (
		<Layout>
			<SEO title="Baushe blog"/>
			<Container className="py-5">
				<h2>Articles</h2>
				<CardColumns>
					{data.allPrismicBlog.nodes.map((node) => {
						const { title: { text: title }, content: { text: content }, preview_img: { url: previewUrl } } = node.data;
						return (
							<Card className="shadow-sm" key={node.uid}>
								<Card.Img variant="top" src={previewUrl} />
								<Card.Body>
									<Card.Title>{title}</Card.Title>
									<Card.Text className="text-muted">{content.slice(0, 150)}...</Card.Text>
									<div className="text-right">
										<Link to={`/${node.uid}`}>Read more <FaArrowRight /></Link>
									</div>
								</Card.Body>
							</Card>
						)
					})}
				</CardColumns>
			</Container>
		</Layout>
	);
}

export default Blog
